<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1 class="title">Event Rewards</h1>
    <router-link to="/events">Back to events</router-link>
    <top-banner banner-name="eventRewardHeader" bg-color="transparent" color="#214163" />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from '@/shared/native';
import { SiteEvent, SiteEventTask, SiteEventTaskSubmission } from '@/shared/types/static-types';
import TopBanner from '@/shared/pages/home/components/TopBanner.vue';


@Options({
  name: 'EventRewardsPage',
  components: {
    TopBanner,
  },
})
export default class EventRewardsPage extends Vue {
  public async mounted() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
</script>
<style lang="sass" scoped>
.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
.submission-form
  margin-bottom: 1rem
.no-select
  user-select: none !important
.description
  white-space: pre-wrap
  background-color: #6D6C6C
  color: #FFF
  font-size: 12px
  .link-color
    color: #e3a7ff
.expired
  color: gray !important
.event-name
  font-size: 1.15rem
</style>
